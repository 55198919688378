import React, { useState, useEffect } from 'react';
import { FaSun, FaMoon } from 'react-icons/fa'; // Icons for light and dark mode
import './ThemeToggle.css'; // Include the CSS for styling

const ThemeToggle = () => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light'); // Set initial theme

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme); // Store the current theme in localStorage
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'dark' ? 'light' : 'dark'));
  };

  return (
    <div className="theme-toggle-container">
    Dark Mode: 
      {/* Toggle Switch */}
      <div className={`theme-toggle-switch ${theme === 'dark' ? 'on' : 'off'}`} onClick={toggleTheme}>
        <div className="theme-toggle-slider">
          {theme === 'dark' ? <FaMoon className="theme-toggle-icon" /> : <FaSun className="theme-toggle-icon" />}
        </div>
      </div>

    </div>
  );
};

export default ThemeToggle;
