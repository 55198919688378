import React from 'react';
import {FaRegStar, FaStar, FaStarHalf} from "react-icons/fa";

const StarRating = () => {
    return <div>Star Rating
        <FaStar style={{fill: '#ebc963'}}/>
        <FaStarHalf/>
        <FaRegStar/>
        <FaRegStar/>
        <FaRegStar/>
    </div>
}

export default StarRating;
