import React from 'react'
import './Login.css';
import Logo from '../../Logo';

const Login = () => {
    return <div className={"login"}>
        <Logo />
        <span className={"label"}>Email </span><input className={"input"} type={"text"} name={"email"}/><br/>
        <span className={"label"}>Password </span><input className={"input"} type={"password"}/><br/>
        <a href="/editor"><input type={"submit"} className={'submit'} value={'/editor'}/></a>
    </div>
}

export default Login;
