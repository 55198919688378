import React from 'react'

const Logo = (props) => {
    return <div className={`logo-text ${props.className}`}>
        Polletra
        {/*<svg height="90" width="100" xmlns="http://www.w3.org/2000/svg">*/}
        {/*    <line x1="0" y1="0" x2="0" y2="20" className={"l1"}/>*/}
        {/*    <line x1="0" y1="25" x2="100" y2="25" className={"l2"}/>*/}
        {/*    <line x1="0" y1="40" x2="100" y2="40" className={"l3"}/>*/}
        {/*</svg>*/}
    </div>
}

export default Logo;
