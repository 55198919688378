import {FaArrowDown, FaArrowLeft, FaArrowRight, FaArrowUp, FaRegClock } from "react-icons/fa";
import 'whatwg-fetch';
import ThemeToggle from '../../Components/ThemeToggle';
import './editor.css'

function Editor() {
    let data = {};
    let styles = {};
    styles.direction = 'right';
    data.question = "Are you ready to create a poll?";
    let options = {};
    options.option1 = "Option Ones";
    options.option2 = "Option Two";
    options.option3 = "Option Three";

    return (<div className="App">
        <header className="App-header">
       <ThemeToggle />
            <div> Appear from <FaArrowRight/> <FaArrowUp/> <FaArrowLeft/> <FaArrowDown/></div>
            <FaRegClock/>
          <textarea className="staticUrl"> Add data here : as static data or URL</textarea>
          <textarea className="staticUrl" />
          <textarea className="staticUrl"/>
        Data URL    :  Cane be a websocket! hook! kind 

          output generate the output. Create SSR file so that you can embed that in remote 


          remote url : mbhasin.Components
         
        </header>

    </div>);
}

export default Editor;
