import React from 'react';
import { SlLike, SlDislike } from "react-icons/sl";

const LikeDislike = () => {
    return <div>Like Dislike Poll
     <SlLike /><SlDislike />
    </div>
}

export default LikeDislike;
