import Polletra from './polletra';
import './App.css';
import Index from './Components/Login/Login';
import 'whatwg-fetch';
import { createApi } from 'unsplash-js';



import ThemeToggle from "./Components/ThemeToggle.js";

const unsplash = createApi({
  accessKey: 'Uh0SniZxQMZHSyeuv9NWuSnqTCsaqFhbRWhTZmYIBZg',
  // `fetch` options to be sent with every request
  headers: { 'X-Custom-Header': 'foo' },
});

/*

- Learn how to make ajax call
- Learn how to do it through hooks
- WTF is redux shit

 */

function App() {
    let data = {};
    let styles = {};
    styles.direction = 'right';
    data.question = "Are you ready to create a poll?";
    let options = {};
    options.option1 = "Option Ones";
    options.option2 = "Option Two";
    options.option3 = "Option Three";
console.log(unsplash.photos.get(
  { photoId: '123' },
  // `fetch` options to be sent only with _this_ request
  { headers: { 'X-Custom-Header-2': 'bar' } },
));
    return (<div className="App">
        <header className="App-header">
       <ThemeToggle />
          
            <Index/>
        
        </header>

        <Polletra question={data.question} options={options}/>
    </div>);
}

export default App;
