import { FaArrowDown, FaArrowLeft, FaArrowRight, FaArrowUp, FaRegClock } from "react-icons/fa";
import 'whatwg-fetch';
import ThemeToggle from '../../Components/ThemeToggle';
import {LikeDislike, Question, StarRating} from "../../Components/Polls"
import Quiz from "../../Components/Quiz/Quiz"

function Editor() {
  let data = {};
  let styles = {};
  styles.direction = 'right';
  data.question = "Are you ready to create a poll?";
  let options = {};
  options.option1 = "Option Ones";
  options.option2 = "Option Two";
  options.option3 = "Option Three";

  return (<div className="App">
    <header className="App-header">
      <ThemeToggle />
      <div> Appear from <FaArrowRight /> <FaArrowUp /> <FaArrowLeft /> <FaArrowDown /></div>
      <FaRegClock />

            <LikeDislike/>
            <Question/>
            <StarRating/>
            <Quiz />


    </header>
  </div>);
}

export default Editor;
