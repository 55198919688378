import React, { useState } from "react";

import "./quiz.css";
let questions = [
  {
    questionText: "What is capital of India?",
    answers: [
      {
        answerText: "New Delhi",
        correctAnswer: true,
      },
      {
        answerText: "Mumbai",
        correctAnswer: false,
      },
      {
        answerText: "Kolkotta",
        correctAnswer: false,
      },
      {
        answerText: "Islamabad",
        correctAnswer: false,
      },
    ],
  },
  {
    questionText: "What is capital of Pakistan?",
    answers: [
      {
        answerText: "Islamabad",
        correctAnswer: true,
      },
      {
        answerText: "Karachi",
        correctAnswer: false,
      },
      {
        answerText: "Lahore",
        correctAnswer: false,
      },
      {
        answerText: "Rawalpindi",
        correctAnswer: false,
      },
    ],
  },
  {
    questionText: "What is capital of Afghanistan?",
    answers: [
      {
        answerText: "Kabul",
        correctAnswer: true,
      },
      {
        answerText: "Kandahar",
        correctAnswer: false,
      },
      {
        answerText: "Jalalabad",
        correctAnswer: false,
      },
      {
        answerText: "Herat",
        correctAnswer: false,
      },
    ],
  },
  {
    questionText: "What is capital of Iran?",
    answers: [
      {
        answerText: "Tehran",
        correctAnswer: true,
      },
      {
        answerText: "Mashhad",
        correctAnswer: false,
      },
      {
        answerText: "Isfahan",
        correctAnswer: false,
      },
      {
        answerText: "Isfahan",
        correctAnswer: false,
      },
    ],
  },
];

const Quiz = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const handleAnswerSelect = () => {
    if (currentQuestion + 1 < questions.length) {
      setCurrentQuestion(currentQuestion + 1);
      console.log(currentQuestion, questions.length);
    } else {
      setCurrentQuestion(0);
    }
  };

  return (
    <div className="quiz">
      <h4>{questions[currentQuestion].questionText}</h4>
      {questions[currentQuestion].answers.map((answer) => (
        <button
          className={answer.correctAnswer ? "correct-answer" : "quiz-answers"}
          onClick={handleAnswerSelect}
        >
          {answer.answerText}
        </button>
      ))}
    </div>
  );
};

export default Quiz;
